import { type Component } from "solid-js";
import {  Collapsible } from "~/components/ui";

export const DefaultCareInstr: Component = () => {
  return (

    <Collapsible
      name="care-instructions"
      trigger="Care Instructions"
      triggerClass="font-bold"
      contentClass="text-roma-dark-grey text-sm child:pb-3 leading-7"
    >
      <p class="my-4">
        To clean your frame, please use a soft, dry cloth with gentle pressure.
        Avoid using harsh chemicals or abrasives that may cause damage. With
        proper care, you can help preserve your frame's beauty and longevity.
      </p>
    </Collapsible>
  );
};
